import React from "react";
import classNames from "classnames";

export type PanelProps = React.PropsWithChildren<{
  title?: string;
  badge?: React.ReactNode;
  subtitle?: string;
  className?: string;
  contentClassName?: string;
  controls?: React.ReactNode;
}>;

/**
 * A simple container with a header, wrapped in a rounded border
 */
export const Panel = React.forwardRef<HTMLDivElement, PanelProps>(
  (
    { title, subtitle, badge, className, contentClassName, controls, children },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={classNames(
          className,
          "rounded-large border border-deprecated-gray-light",
        )}
      >
        {title || subtitle || badge || controls ? (
          <div className="flex items-center justify-between rounded-t-large bg-deprecated-gray-lightest p-8 pl-12 text-deprecated-gray-darkest">
            <div className="flex items-center gap-12">
              {!subtitle ? (
                <>
                  <p className="text-sm leading-4">{title}</p>
                  {badge}
                </>
              ) : (
                <div>
                  <div className="flex gap-12">
                    <p className="text-sm leading-3">{title}</p>
                    {badge}
                  </div>
                  <p className="text-xs leading-2 text-deprecated-gray-darkest">
                    {subtitle}
                  </p>
                </div>
              )}
            </div>

            <div className="flex items-center">{controls}</div>
          </div>
        ) : null}
        <div
          className={classNames(
            "bg-background overflow-clip rounded-b-large",
            contentClassName,
          )}
        >
          {children}
        </div>
      </div>
    );
  },
);
