import React from "react";
import "style/index.css";
import { twMerge } from "tenaissance/twMerge";
import { IconName, Icon } from "../Icon";
import { Link } from "react-router-dom";
import { useEnvironment__unsafe } from "lib/environmentSwitcher/context";

const SIZE_VARIANTS = {
  sm: "h-[36px] py-md px-lg text-sm",
  md: "h-[40px] py-[10px] px-[14px] text-sm",
  lg: "h-[44px] py-[10px] px-xl text-md",
};

const BUTTON_VARIANTS = {
  primary:
    "border-core-slate bg-core-slate active:bg-core-slate text-white hover:border-gray-800 hover:bg-gray-950 focus-within:ring-core-jade-green/[.24] focus-within:ring-4",
  secondary:
    "bg-white border-gray-200 text-gray-600 active:text-gray-600 active:bg-white hover:bg-gray-200 hover:text-gray-800 focus-within:ring-gray-400/[.14] focus-within:ring-4",
  tertiary:
    "border-0 text-gray-600 active:text-gray-600 active:bg-white hover:bg-gray-50 hover:text-gray-800 focus-within:none shadow-none",
  linkGray:
    "h-[initial] p-0 border-0 text-gray-500 active:text-gray-500 hover:text-gray-600 focus-within:none outline:none focus-within:text-gray-600 shadow-none",
  linkDestructive:
    "h-[initial] p-0 border-0 text-error-600 active:text-error-600 hover:text-error-800 focus-within:none outline:none focus-within:text-error-800 shadow-none",
};

const BUTTON_DISABLED_VARIANTS = {
  primary: "pointer-events-none border-gray-200 bg-gray-200 text-gray-400",
  secondary: "pointer-events-none text-gray-400 bg-gray-50",
  tertiary: "pointer-events-none text-gray-400",
  linkGray: "pointer-events-none text-gray-400",
  linkDestructive: "pointer-events-none text-gray-200",
};

export interface ButtonProps {
  /** Text string for the Button */
  text: string;
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /** Icon to place at the start of the button, before the Button text */
  leadingIcon?: IconName;
  /** Icon to place at the end of the button, after the Button text */
  trailingIcon?: IconName;
  /** Default: primary - Sets the colors of the background, border, icon and text of the Button */
  theme?: keyof typeof BUTTON_VARIANTS;
  /** Callback invoked when the user clicks (press and release) on Button with the mouse or keyboard. */
  onClick?: (e: React.MouseEvent) => void;
  /** The button will becom an anchor tag. If the URI provided is to an external location, please add `isExternalLink` as well. */
  linkTo?: string;
  /** If the route passed to the `linkTo` prop is meant to open an external URI in a new tab */
  isExternalLink?: boolean;
  /** The button is disabled if set to true, preventing interaction and inactive */
  disabled?: boolean;
  /** Default: md - sm: 36px, md: 40px, lg: 44px */
  size?: "sm" | "md" | "lg";
  /** Default: button - Use "submit" if Button is used with or associated with a form */
  type?: "button" | "submit";
  /** Default: false - Use to indicate that the button is in loading state */
  loading?: boolean;
  /** Render the button to the full width of the parent container */
  showFullWidth?: boolean;
  /** (optional) - used to identify the button in playwright tests */
  ["data-testid"]?: string;
}
/** This is a shim for Safari to properly focus on the clicked button, allowing us to apply and outline */
function focusButtons(e: React.BaseSyntheticEvent) {
  if (e.target.matches("button, button *")) {
    const button = e.target.closest("button");
    if (button) {
      button.focus();
    }
  }
}

/**
 *  Buttons communicate actions that users can take. They can be used alone
 *  for immediate action, a form or as a trigger for another component.
 */
export const Button: React.FC<ButtonProps> = ({
  className,
  disabled,
  isExternalLink,
  leadingIcon,
  linkTo,
  loading,
  onClick,
  showFullWidth = false,
  size = "md",
  text,
  theme = "primary",
  trailingIcon,
  type = "button",
  "data-testid": dataTestId,
}) => {
  // We use the button in certain contexts where we don't have the environment initialized, e.g. impersonation, so we need to use the unsafe hook
  // If we do happen to use a link without the environment being initialized, we will throw an error
  const environmentHook = useEnvironment__unsafe();
  const classnames = twMerge(
    "border rounded-md inline-flex items-center group font-semibold shadow-xs focus-within:outline-none whitespace-nowrap w-fit",
    SIZE_VARIANTS[size],
    BUTTON_VARIANTS[theme],
    disabled && BUTTON_DISABLED_VARIANTS[theme],
    showFullWidth && "w-full",
    showFullWidth && (trailingIcon || leadingIcon)
      ? "justify-between"
      : "justify-center",
    className,
  );

  if (loading) {
    leadingIcon = "loading02";
  }

  let element;
  const commonChildren = (
    <>
      {leadingIcon && (
        <Icon
          icon={leadingIcon}
          className={twMerge(
            "pointer-events-none mr-xs",
            loading ? "animate-spin" : "",
          )}
          size={20}
        />
      )}
      <span className="overflow-hidden overflow-ellipsis">{text}</span>
      {trailingIcon && (
        <Icon
          icon={trailingIcon}
          className="pointer-events-none ml-xs"
          size={20}
        />
      )}
    </>
  );

  if (linkTo) {
    const commonProps = {
      className: classnames,
      onClick,
      "aria-disabled": disabled,
      children: commonChildren,
      "data-testid": dataTestId,
    };

    if (isExternalLink) {
      element = <a {...commonProps} target="_blank" href={linkTo} />;
    } else {
      if (!environmentHook) {
        throw new Error("Environment is not initialized for link");
      }
      element = (
        <Link {...commonProps} to={environmentHook.prefixUrl(linkTo)} />
      );
    }
  } else {
    element = (
      <button
        className={classnames}
        onClick={(e) => {
          focusButtons(e);
          onClick?.(e);
        }}
        disabled={disabled}
        type={type}
        data-testid={dataTestId}
      >
        {commonChildren}
      </button>
    );
  }

  return element;
};
