import React, { ReactNode } from "react";
import classnames from "classnames";
import styles from "./index.module.less";
import { Icon } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { Button } from "tenaissance/components/Button";
import { InternalLink } from "components/Typography";
import { assertUnreachable } from "lib/util";

interface SortingIndicatorProps {
  direction: "asc" | "desc" | "none";
}

const SortingIndicator: React.FC<SortingIndicatorProps> = ({ direction }) => {
  const upClassname = classnames({
    [styles.active]: direction === "asc",
  });
  const downClassname = classnames({
    [styles.active]: direction === "desc",
  });
  return (
    <div className={styles.sortingIndicator}>
      <Icon icon="caretUp" className={upClassname} />
      <Icon icon="caretDown" className={downClassname} />
    </div>
  );
};

export type Column<T> = {
  render: (row: T, isSubRow?: boolean) => ReactNode;
  header: string | ReactNode;
  width?: number;
  alignment?: "left" | "top-left" | "right" | "top-right";
  sort?: "none" | "asc" | "desc";
  headerClassName?: string;
};

type Page = number | "prev" | "next";

export type RowSpec<T> =
  | { type: "data"; data: T; subRow?: boolean }
  | { type: "full-width-heading"; text: string };

type RowData<T> = T & { subRows?: T[] | null; cellKey?: string };

type Props<T extends object> = {
  /** Column configuration and renderers. */
  columns: Column<T>[];
  /** If configured, render pagination buttons at the bottom of the table. */
  paginationButtons?: {
    page: Page;
    disabled?: boolean;
    selected?: boolean;
    onClick: () => void;
  }[];
  onSortClick?: (index: number) => void;
  rowRoutePath?: (row: T) => string;
  noBottomBorder?: boolean;
  className?: string;
} & (
  | {
      /** Provide raw data if all you need to do is render it to a table. */
      data: RowData<T>[];
      rowSpecs?: undefined;
    }
  | {
      /** Provide data using RowSpecs if you need more customization. For example, you can add
      full width headings between rows. */
      rowSpecs: RowSpec<T>[];
      data?: undefined;
    }
);

/**
  SimpleTable uses basic markup (instead of a library) to create a table component.

  When should you use this component vs. react-table?  When you don't need advanced functionality
  like: Sorting, Pagination, Row collapse/expand, etc. This component also allows for stable updates
  to the table data without a full re-render.  This is useful for cells that have inputs (does not
  force a full re-render when an input is changed)
*/
export function SimpleTable<T extends Object>(props: Props<T>) {
  const getRows = (rowSpecs: RowSpec<T>[]) => {
    const rows = [];

    const createRowsFromSpec = (rowSpec: RowSpec<T>, rowIndex: string) => {
      if (rowSpec.type === "full-width-heading") {
        return [
          <tr key={rowIndex} className="bg-deprecated-primary-50">
            <td />
            <td colSpan={props.columns.length} className="p-0">
              {rowSpec.text}
            </td>
            <td />
          </tr>,
        ];
      } else if (rowSpec.type === "data") {
        return createRows(rowSpec.data, rowIndex, rowSpec.subRow);
      } else {
        assertUnreachable(rowSpec, "Unexpected row type");
      }
    };

    const createRows = (
      data: RowData<T>,
      rowIndex: string,
      subRow?: boolean,
    ) => {
      const rows = [];

      rows.push(
        /*
          Currently the table only needs for cells to be updated vs. creating new rows/columns, therefor it's OK to use indices for keys
        */
        <tr
          key={rowIndex}
          className={classnames({
            [styles.expanded]: subRow,
            [styles.clickable]: props.rowRoutePath,
          })}
        >
          <td />
          {props.columns.map((column, colIndex) => {
            const widthStyle =
              colIndex === 0
                ? { minWidth: column.width }
                : { width: column.width };
            return (
              <td
                style={widthStyle}
                key={
                  /* Optionally pass a key */
                  data.cellKey
                    ? `${data.cellKey}:${String(colIndex)}`
                    : `${rowIndex}:${colIndex}`
                }
                className={classnames(
                  column.alignment?.startsWith("top") ? "align-top" : "",
                  column.headerClassName,
                )}
              >
                <div
                  className={classnames(
                    styles.cell,
                    styles[column.alignment ?? "left"],
                  )}
                >
                  {props.rowRoutePath ? (
                    <InternalLink
                      className={styles.cellContainer}
                      routePath={props.rowRoutePath(data)}
                    >
                      {column.render(data, subRow)}
                    </InternalLink>
                  ) : (
                    column.render(data, subRow)
                  )}
                </div>
              </td>
            );
          })}
          <td />
        </tr>,
      );
      if (data.subRows) {
        data.subRows.map((subRow, subRowIndex) =>
          rows.push(...createRows(subRow, `${subRowIndex}:${rowIndex}`, true)),
        );
      }

      return rows;
    };
    for (let i = 0; i < rowSpecs.length; i++) {
      const rowSpec = rowSpecs[i];
      rows.push(...createRowsFromSpec(rowSpec, String(i)));
    }
    return rows;
  };

  return (
    <div className={classnames(styles.tableContainer, props.className)}>
      <table className={classnames(styles.table)}>
        <thead>
          <tr>
            {/* The first and last column are styled differently and they should always exist on the table */}
            <th />
            {props.columns.map((c, index) => {
              return (
                <th
                  key={index}
                  className={c.alignment?.startsWith("top") ? "align-top" : ""}
                >
                  <div
                    className={classnames(
                      styles.header,
                      styles[c.alignment ?? "left"],
                      { [styles.clickable]: !!c.sort },
                    )}
                    onClick={() => !!c.sort && props.onSortClick?.(index)}
                  >
                    {c.sort && <SortingIndicator direction={c.sort} />}
                    <span>{c.header}</span>
                  </div>
                </th>
              );
            })}
            <th />
          </tr>
        </thead>
        <tbody
          className={classnames({
            [styles.noBottomBorder]: props.noBottomBorder,
          })}
        >
          {"rowSpecs" in props && props.rowSpecs !== undefined
            ? getRows(props.rowSpecs)
            : getRows(props.data.map((data) => ({ type: "data", data })))}
        </tbody>
      </table>
      {props.paginationButtons ? (
        <div className={styles.pagination}>
          <div className={styles.pages}>
            {props.paginationButtons.map((button, i) => {
              if (button.page === "prev" || button.page === "next") {
                return (
                  <IconButton
                    key={i}
                    onClick={button.onClick}
                    className={styles.button}
                    disabled={button.disabled}
                    theme="secondary"
                    icon={
                      button.page === "prev" ? "chevronLeft" : "chevronRight"
                    }
                    size="sm"
                  />
                );
              }

              return (
                <Button
                  key={i}
                  onClick={button.onClick}
                  className={styles.button}
                  text={String(button.page)}
                  theme="secondary"
                  size="sm"
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
