import React from "react";
import classNames from "classnames";

import { Panel, PanelProps } from "../Panel";
import { InPanelTable, InPanelTableProps } from "./InPanelTable";
export type TablePanelProps<T extends object> = Omit<
  InPanelTableProps<T>,
  "containerClassName"
> & {
  tableContainerClassName?: string;
  noInPanelTableHeaderStyling?: boolean;
} & PanelProps;

/**
 * A table with a header, wrapped in a rounded border
 */
export function TablePanel<T extends object>({
  title,
  badge,
  className,
  contentClassName,
  controls,
  tableContainerClassName,
  noInPanelTableHeaderStyling,
  emptyState,
  data,
  loading,
  ...tableProps
}: TablePanelProps<T>) {
  return (
    <Panel
      title={title}
      badge={badge}
      className={className}
      contentClassName={classNames(contentClassName)}
      controls={controls}
    >
      {loading || data.length ? (
        <InPanelTable<T>
          panelHeader={noInPanelTableHeaderStyling ? false : !title}
          containerClassName={tableContainerClassName}
          data={data}
          loading={loading}
          {...tableProps}
        />
      ) : (
        emptyState
      )}
    </Panel>
  );
}
