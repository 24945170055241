import React from "react";
import classNames from "classnames";
import { Headline } from "design-system";
import { ProductContainer } from "components/ProductContainer";
import { Product } from "types/generated-graphql/__types__";
import { NoSubLineItems } from "components/Invoice/components/NoSubLineItems";
import { GroupedProductChargeLineItemFragment } from "components/Invoice/lib/groupedProductChargeLineItemUtils";
import { SubLineItemsTable } from "components/Invoice/components/LineItem/lineItems/SubLineItems";
import { SubLineItemFieldsFragment } from "components/Invoice/components/LineItem/fragments.graphql";
import { RowSpec } from "components/SimpleTable";
import { EmbeddableDashboardContext } from "lib/embeddableDashboardContext";

/**
 * Render GroupedProductChargeLineItems in this hierarchy:
 *
 * - Product 1
 *   - Group 1
 *     - SubLineItem 1
 *     - SubLineItem 2
 *     - ...
 *   - Group 2
 *     - SubLineItem 1
 *     - SubLineItem 2
 *     - ...
 *   - ...
 */
export const GroupedProductChargeLineItems: React.FC<{
  invoiceId: string;
  product: Pick<Product, "id" | "name">;
  lineItems: GroupedProductChargeLineItemFragment[];
  showChargesWithZeroUsage: boolean;
}> = (props) => {
  const { getGroupDisplayName, getGroupValueDisplayName } =
    EmbeddableDashboardContext.useContainer();

  const groupKey =
    props.lineItems.length === 0 ? null : props.lineItems[0].group_key;

  const rowSpecs: RowSpec<SubLineItemFieldsFragment>[] = [];

  for (const lineItem of iterateLineItems(props.lineItems)) {
    switch (lineItem.__typename) {
      case "GroupedProductChargeLineItem":
        const groupKey = getGroupDisplayName(lineItem.group_key);
        const groupValue = getGroupValueDisplayName(
          lineItem.group_key,
          lineItem.group_value,
        );

        rowSpecs.push({
          type: "full-width-heading",
          text: `${groupKey}: "${groupValue}"`,
        });
        break;
      case "TierChargeLineItem":
        rowSpecs.push({
          type: "data",
          data: lineItem,
          subRow: true,
        });
        break;
      default:
        rowSpecs.push({
          type: "data",
          data: lineItem,
        });
        break;
    }
  }

  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  return (
    <div className="p-12">
      <ProductContainer
        title={
          <Headline
            level={5}
            className={
              isEmbeddableDashboard
                ? "text-default-font"
                : "text-deprecated-primary-dark"
            }
          >
            {props.product.name}
          </Headline>
        }
        groupKey={groupKey}
      >
        <div className="-mb-12 p-12 pb-0">
          {rowSpecs.length > 0 ? (
            <SubLineItemsTable
              invoiceId={props.invoiceId}
              rowSpecs={rowSpecs}
            />
          ) : (
            <NoSubLineItems
              showChargesWithZeroUsage={props.showChargesWithZeroUsage}
            />
          )}
        </div>
      </ProductContainer>
    </div>
  );
};

export const LineItemGroupHeading: React.FC<{
  groupKey: string;
  groupValue: string | null;
  bgColor?: "grey";
}> = (props) => {
  return (
    <div
      className={classNames(
        "-mb-[6px] border-b border-solid border-deprecated-gray-100 px-12 py-8 font-mono text-xs",
        { "bg-gray-50": props.bgColor === "grey" },
      )}
    >
      {props.groupKey}: <b className="font-medium">"{props.groupValue}"</b>
    </div>
  );
};

/**
 * Infer type of all line items and sub line items.
 */
type FlattenLineItems<T> = T extends {
  sub_line_items?: Array<infer U> | null | undefined;
}
  ? T | FlattenLineItems<U>
  : T;

/**
 * Return iterator that recursively DFS's all line items and their sub line items.
 */
function* iterateLineItems(
  lineItems: FlattenLineItems<GroupedProductChargeLineItemFragment>[],
): IterableIterator<FlattenLineItems<GroupedProductChargeLineItemFragment>> {
  for (const lineItem of lineItems) {
    yield lineItem;
    if ("sub_line_items" in lineItem) {
      yield* iterateLineItems(lineItem.sub_line_items ?? []);
    }
  }
}
