import React from "react";
import Decimal from "decimal.js";

import styles from "./index.module.less";
import { CreditType } from "types/credit-types";
import { Headline, Subtitle } from "design-system";
import {
  displayCreditTypeName,
  RoundedCurrency,
  USD_CREDIT_ID,
} from "lib/credits";
import { renderDateRange } from "lib/time";
import classNames from "classnames";
import { TotalsFieldsFragment } from "./fragments.graphql";
import { CommitReference } from "pages/Contracts/components/CommitReference";
import { useFeatureFlag } from "lib/launchdarkly";
import * as Sentry from "@sentry/react";
import { EmbeddableDashboardContext } from "lib/embeddableDashboardContext";

type LineItem = TotalsFieldsFragment["line_items"][number];

type OverageLineItem = LineItem & {
  __typename: "OverageLineItem";
};
type CreditLineItem = LineItem & { __typename: "CreditLineItem" };
type MinimumLineItem = LineItem & {
  __typename: "MinimumLineItem";
};
type TrialDiscountLineItem = LineItem & {
  __typename: "TrialDiscountLineItem";
};
type ContractAppliedCommitLineItem = LineItem & {
  __typename: "ContractAppliedCommitLineItem";
};
type ContractConversionLineItem = LineItem & {
  __typename: "ContractConversionLineItem";
};

export const Totals: React.FC<{
  invoice: TotalsFieldsFragment & { id: string };
  creditTypeConversions?: {
    custom_credit_type: CreditType;
    fiat_per_custom_credit: string;
  }[];
}> = ({ invoice, creditTypeConversions }) => {
  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  const isContractInvoice = [
    "ContractUsageInvoice",
    "AdhocContractUsageInvoice",
    "ContractScheduledInvoice",
    "ContractProServiceInvoice",
  ].includes(invoice.__typename);
  let showCPUWork =
    useFeatureFlag<boolean>("contract-cpus", false) && isContractInvoice;

  if (isEmbeddableDashboard) {
    showCPUWork = true;
  }

  // Accumulate the list of credit types that are different from the invoice credit type. This order is used to display
  // the subtotals below.
  const customCreditTypesInOrder: CreditType[] = [];

  // Accumulate the totals for each credit type. This is used to display the subtotals below.
  const totalsPerCreditType: Record<
    string,
    {
      subTotal: Decimal;
      total: Decimal;
    }
  > = {
    [invoice.credit_type.id]: {
      subTotal: new Decimal(0),
      total: new Decimal(0),
    },
  };

  const minimumLineItems: MinimumLineItem[] = [];
  const trialDiscountLineItems: TrialDiscountLineItem[] = [];
  const creditLineItems: CreditLineItem[] = [];
  const overageLineItems: OverageLineItem[] = [];
  const contractConversionLineItems: ContractConversionLineItem[] = [];
  const appliedCommitsByCreditTypeThenId = new Map<
    string,
    Map<
      string,
      {
        commit: ContractAppliedCommitLineItem["commit_with_segment"]["commit_union"];
        total: Decimal;
      }
    >
  >();

  let hasNonZeroLineItemsInCustomCreditType = false;

  for (const lineItem of invoice.line_items) {
    const creditTypeID = lineItem.credit_type.id;

    if (lineItem.credit_type.id !== invoice.credit_type.id) {
      if (
        !customCreditTypesInOrder
          .map((ct) => ct.id)
          .includes(lineItem.credit_type.id)
      ) {
        // Add the credit type to the list of custom credit types if it's not already there
        customCreditTypesInOrder.push(lineItem.credit_type);
      } else if (Number(lineItem.total) !== 0) {
        hasNonZeroLineItemsInCustomCreditType = true;
      }
    }

    // Initialize the totals for this credit type if they haven't been initialized yet
    if (!totalsPerCreditType[creditTypeID]) {
      totalsPerCreditType[creditTypeID] = {
        subTotal: new Decimal(0),
        total: new Decimal(0),
      };
    }
    switch (lineItem.__typename) {
      // These line item types are included in the subtotal and total
      case "ProductChargeLineItem":
      case "GroupedProductChargeLineItem":
      case "LegacyContractLineItem":
      case "LegacyLineItem":
      case "CreditPurchaseLineItem":
      case "ContractChargeLineItem":
      case "ContractCommitLineItem":
      // TODO: Determine if discount line items should be in the subtotal or not
      // https://linear.app/getmetronome/issue/PRA-269/should-discountlineitems-be-included-in-the-invoice-subtotal
      case "ContractDiscountLineItem":
      case "ContractProServiceLineItem":
      case "ContractAWSRoyaltyLineItem":
      case "ContractAWSProServiceRoyaltyLineItem":
      case "ContractGCPRoyaltyLineItem":
      case "ContractGCPProServiceRoyaltyLineItem":
      case "ContractPostpaidTrueupLineItem":
      case "ContractSubscriptionLineItem":
      case "ContractUsageLineItem":
      case "ContractConversionLineItem": {
        if (lineItem.__typename === "ContractConversionLineItem") {
          contractConversionLineItems.push(lineItem);
        }
        totalsPerCreditType[creditTypeID].subTotal = totalsPerCreditType[
          creditTypeID
        ].subTotal.plus(lineItem.total);
        totalsPerCreditType[creditTypeID].total = totalsPerCreditType[
          creditTypeID
        ].total.plus(lineItem.total);
        continue;
      }

      // These line item types are not included in the subtotal
      case "CreditLineItem":
      case "MinimumLineItem":
      case "OverageLineItem":
      case "ContractAppliedCommitLineItem":
      case "TrialDiscountLineItem": {
        if (lineItem.__typename === "CreditLineItem") {
          creditLineItems.push(lineItem);
        } else if (lineItem.__typename === "MinimumLineItem") {
          minimumLineItems.push(lineItem);
        } else if (lineItem.__typename === "TrialDiscountLineItem") {
          trialDiscountLineItems.push(lineItem);
        } else if (lineItem.__typename === "OverageLineItem") {
          overageLineItems.push(lineItem);
        } else if (lineItem.__typename === "ContractAppliedCommitLineItem") {
          if (
            !showCPUWork &&
            lineItem.credit_type.id !== invoice.credit_type.id
          ) {
            continue;
          }
          if (
            lineItem.commit_with_segment.commit_union.__typename ===
            "PostpaidCommit"
          ) {
            continue;
          }

          const appliedCreditTypeId =
            lineItem.commit_with_segment.commit_union.__typename ===
            "PrepaidCommit"
              ? lineItem.commit_with_segment.commit_union.access_schedule
                  .credit_type.id
              : USD_CREDIT_ID;
          if (!appliedCommitsByCreditTypeThenId.has(appliedCreditTypeId)) {
            appliedCommitsByCreditTypeThenId.set(
              appliedCreditTypeId,
              new Map(),
            );
          }
          const commitId = lineItem.commit_with_segment.commit_union.id;
          const appliedCommits =
            appliedCommitsByCreditTypeThenId.get(appliedCreditTypeId);
          const applied = appliedCommits?.get(commitId);
          if (!applied) {
            appliedCommits?.set(commitId, {
              commit: lineItem.commit_with_segment.commit_union,
              total: new Decimal(lineItem.total),
            });
          } else {
            applied.total = applied.total.plus(lineItem.total);
          }
        } else {
          lineItem satisfies never;
        }

        totalsPerCreditType[creditTypeID].total = totalsPerCreditType[
          creditTypeID
        ].total.plus(lineItem.total);
        continue;
      }
    }
    // TODO(smarx): Remove this once the new GraphQL schema is published that
    // removes this type.
    if (lineItem.__typename !== "ContractRolloverCommitLineItem") {
      // If this is failing to compile, you probably need to add a new case to the switch statement above
      lineItem satisfies never;
    }
  }

  if (
    totalsPerCreditType[invoice.credit_type.id].total
      .sub(new Decimal(invoice.total))
      .absoluteValue()
      .greaterThan(0.000000001)
  ) {
    throw new Error(
      `Invoice total does not match the sum of its line items, or the difference is greater than 0.000000001. ` +
        `Expected ${totalsPerCreditType[
          invoice.credit_type.id
        ].total.toString()}, got ${invoice.total}`,
    );
  }

  // Show the time interval for credit line items if the invoice is an arrears invoice and the time interval
  // of the deduction is different from the invoice's period.
  const showCreditTimeInterval = (li: CreditLineItem) =>
    li.time_range &&
    invoice.__typename === "ArrearsInvoice" &&
    (li.time_range.inclusive_start_date !== invoice.inclusive_start_date ||
      li.time_range.exclusive_end_date !== invoice.exclusive_end_date);

  return (
    <div className={styles.Totals}>
      <div className={styles.contents}>
        {/* credit type subtotals */}
        {customCreditTypesInOrder.map((creditType) => {
          const creditTypeMinimumLineItems = minimumLineItems.filter(
            (li) => li.credit_type.id === creditType.id,
          );
          const creditTypeTrialDiscountLineItems =
            trialDiscountLineItems.filter(
              (li) => li.credit_type.id === creditType.id,
            );
          const creditTypeCreditLineItems = creditLineItems.filter(
            (li) => li.credit_type.id === creditType.id,
          );
          const planOverageLineItem = overageLineItems.find(
            (li) => li.overage_credit_type.id === creditType.id,
          );
          const contractConversionLineItem = contractConversionLineItems.find(
            (li) => li.credit_type.id === creditType.id,
          );

          const shouldDisplayCreditType = showCPUWork
            ? !totalsPerCreditType[creditType.id].subTotal.eq(0) ||
              creditTypeMinimumLineItems.length !== 0 ||
              creditTypeTrialDiscountLineItems.length !== 0 ||
              creditTypeCreditLineItems.length !== 0 ||
              !!contractConversionLineItem
            : true;
          if (!shouldDisplayCreditType) {
            return null;
          }

          return (
            <React.Fragment key={creditType.id}>
              <Headline level={5}>{displayCreditTypeName(creditType)}</Headline>

              <div className={styles.line}>
                <Subtitle level={3}>Subtotal</Subtitle>
                <Subtitle level={3} className={styles.amount}>
                  <RoundedCurrency
                    amount={totalsPerCreditType[creditType.id].subTotal}
                    creditType={creditType}
                  />
                </Subtitle>
              </div>

              {creditTypeMinimumLineItems.map((li, i) => (
                <div key={`creditTypeMinimum${i}`} className={styles.line}>
                  <Subtitle level={3}>
                    Invoice minimum (
                    <RoundedCurrency
                      amount={new Decimal(li.minimum)}
                      creditType={li.credit_type}
                    />
                    )
                  </Subtitle>
                  <Subtitle level={3} className={styles.amount}>
                    <RoundedCurrency
                      amount={new Decimal(li.total)}
                      creditType={li.credit_type}
                    />
                  </Subtitle>
                </div>
              ))}

              {creditTypeTrialDiscountLineItems.map((li, i) => (
                <div
                  key={`creditTypeTrialDiscount${i}`}
                  className={styles.line}
                >
                  <Subtitle level={3}>Trial discount</Subtitle>
                  <Subtitle level={3} className={styles.amount}>
                    <RoundedCurrency
                      amount={new Decimal(li.total)}
                      creditType={li.credit_type}
                    />
                  </Subtitle>
                </div>
              ))}

              {creditTypeCreditLineItems.map((li, i) => (
                <div key={`creditTypeCredit${i}`} className={styles.line}>
                  <Subtitle level={3}>
                    {li.time_range && showCreditTimeInterval(li)
                      ? `Credits applied (${renderDateRange(
                          new Date(li.time_range.inclusive_start_date),
                          new Date(li.time_range.exclusive_end_date),
                          { isUtc: true, excludeUtcLabel: true },
                          false,
                        )}): ${li.credit_grant.name}`
                      : `Credits applied: ${li.credit_grant.name}`}
                  </Subtitle>
                  <Subtitle level={3} className={styles.amount}>
                    <RoundedCurrency
                      amount={new Decimal(li.total)}
                      creditType={li.credit_type}
                    />
                  </Subtitle>
                </div>
              ))}

              {Array.from(
                appliedCommitsByCreditTypeThenId.get(creditType.id)?.values() ??
                  [],
              ).map((li) => (
                <div key={li.commit.id} className={styles.line}>
                  <Subtitle level={3}>
                    <CommitReference {...li.commit} />
                    {li.total.isPositive() ? " returned" : " consumed"}
                  </Subtitle>
                  <Subtitle level={3} className={styles.amount}>
                    <RoundedCurrency
                      amount={new Decimal(li.total)}
                      creditType={creditType}
                    />
                  </Subtitle>
                </div>
              ))}

              <div className={styles.line}>
                <Subtitle level={3}>
                  {showCPUWork ? `Total (${creditType.name})` : "Total"}
                </Subtitle>
                <Subtitle level={3} className={styles.amount}>
                  <RoundedCurrency
                    amount={totalsPerCreditType[creditType.id].total}
                    creditType={creditType}
                  />
                </Subtitle>
              </div>

              {planOverageLineItem ? (
                <div className={styles.line}>
                  <div>
                    <Subtitle level={3}>
                      Subtotal (
                      {displayCreditTypeName(planOverageLineItem.credit_type)})
                    </Subtitle>
                    <Subtitle level={3}>
                      <RoundedCurrency
                        amount={new Decimal(planOverageLineItem.overage_rate)}
                        creditType={planOverageLineItem.credit_type}
                      />{" "}
                      per{" "}
                      {displayCreditTypeName(
                        planOverageLineItem.overage_credit_type,
                      )}
                    </Subtitle>
                  </div>
                  <Subtitle
                    level={3}
                    className={classNames(styles.total, styles.amount)}
                  >
                    <RoundedCurrency
                      amount={new Decimal(planOverageLineItem.total)}
                      creditType={planOverageLineItem.credit_type}
                    />
                  </Subtitle>
                </div>
              ) : null}
            </React.Fragment>
          );
        })}

        {/* invoice total */}
        {/* We only show the name of the invoice credit type if there were _other_ credit types on the invoice too */}
        {customCreditTypesInOrder.length ? (
          <Headline level={5}>
            {displayCreditTypeName(invoice.credit_type)}
          </Headline>
        ) : null}

        {Object.values(
          contractConversionLineItems
            .filter(
              (li) =>
                // Filter for non-zero line items in the invoice's fiat currency
                li.__typename === "ContractConversionLineItem" &&
                invoice.credit_type.id === li.credit_type.id &&
                Number(li.quantity) > 0,
            )
            .reduce<{
              [customCreditTypeId: string]: ContractConversionLineItem[];
            }>((acc, curr) => {
              // Build map to group line items of same CPU
              const customCreditTypeId = curr.custom_credit_type.id;
              if (!acc[customCreditTypeId]) {
                acc[customCreditTypeId] = [];
              }
              acc[customCreditTypeId].push(curr);
              return acc;
            }, {}),
        ).flatMap((contractConversionLineItemsByCustomCreditType, i) => {
          const customCreditType =
            contractConversionLineItemsByCustomCreditType[0].custom_credit_type;
          const conversion = creditTypeConversions?.find(
            (c) => c.custom_credit_type.id === customCreditType.id,
          );
          if (!conversion) {
            Sentry.setContext("context", {
              creditTypeConversions: JSON.stringify(
                creditTypeConversions,
                null,
                2,
              ),
              contractConversionLineItems: JSON.stringify(
                contractConversionLineItemsByCustomCreditType,
                null,
                2,
              ),
              customCreditTypeId: customCreditType.id,
            });
            throw new Error(
              `Credit type conversion for CPU ${customCreditType.id} not found, this is unexpected`,
            );
          }
          // Combine conversion quantites & totals for same CPU since our conversion line items of same CPU are not merged in invoice
          const totals = contractConversionLineItemsByCustomCreditType.reduce(
            (totals, curr) => {
              totals.quantity = totals.quantity.add(new Decimal(curr.quantity));
              totals.total = totals.total.add(new Decimal(curr.total));
              return totals;
            },
            { quantity: new Decimal(0), total: new Decimal(0) },
          );
          const totalQuantityStr = totals.quantity.toString();
          return (
            <div key={i} className={styles.line}>
              <div>
                <Subtitle level={3}>
                  {displayCreditTypeName(customCreditType)} (x
                  {totalQuantityStr.length > 20
                    ? totalQuantityStr.slice(0, 20) + "..."
                    : totalQuantityStr}
                  )
                </Subtitle>
                <Subtitle level={3} className="font-normal">
                  <RoundedCurrency
                    amount={new Decimal(conversion.fiat_per_custom_credit)}
                    creditType={invoice.credit_type}
                  />{" "}
                  per {displayCreditTypeName(customCreditType)}
                </Subtitle>
              </div>
              <Subtitle level={3} className={classNames(styles.amount)}>
                <RoundedCurrency
                  amount={totals.total}
                  creditType={invoice.credit_type}
                />
              </Subtitle>
            </div>
          );
        })}

        {!isContractInvoice || showCPUWork ? (
          <div className={styles.line}>
            <Subtitle level={3}>Subtotal</Subtitle>
            <Subtitle level={3} className={styles.amount}>
              <RoundedCurrency
                amount={totalsPerCreditType[invoice.credit_type.id].subTotal}
                creditType={invoice.credit_type}
              />
            </Subtitle>
          </div>
        ) : null}

        {Array.from(
          appliedCommitsByCreditTypeThenId
            .get(invoice.credit_type.id)
            ?.values() ?? [],
        ).map((li) => (
          <div key={li.commit.id} className={styles.line}>
            <Subtitle level={3}>
              <CommitReference {...li.commit} />
              {li.total.isPositive() ? " returned" : " consumed"}
            </Subtitle>
            <Subtitle level={3} className={styles.amount}>
              <RoundedCurrency
                amount={new Decimal(li.total)}
                creditType={invoice.credit_type}
              />
            </Subtitle>
          </div>
        ))}

        {minimumLineItems
          .filter((li) => li.credit_type.id == invoice.credit_type.id)
          .map((li, i) => (
            <div key={i} className={styles.line}>
              <Subtitle level={3}>
                Invoice minimum (
                <RoundedCurrency
                  amount={new Decimal(li.minimum)}
                  creditType={li.credit_type}
                />
                )
              </Subtitle>
              <Subtitle level={3} className={styles.amount}>
                <RoundedCurrency
                  amount={new Decimal(li.total)}
                  creditType={li.credit_type}
                />
              </Subtitle>
            </div>
          ))}

        {trialDiscountLineItems
          .filter((li) => li.credit_type.id == invoice.credit_type.id)
          .map((li, i) => (
            <div key={i} className={styles.line}>
              <Subtitle level={3}>Trial discount</Subtitle>
              <Subtitle level={3} className={styles.amount}>
                <RoundedCurrency
                  amount={new Decimal(li.total)}
                  creditType={li.credit_type}
                />
              </Subtitle>
            </div>
          ))}

        {creditLineItems
          .filter((li) => li.credit_type.id == invoice.credit_type.id)
          .map((li, i) => (
            <div key={`${invoice.id}:${i}`} className={styles.line}>
              <Subtitle level={3}>
                {li.time_range && showCreditTimeInterval(li)
                  ? `Credits applied (${renderDateRange(
                      new Date(li.time_range.inclusive_start_date),
                      new Date(li.time_range.exclusive_end_date),
                      { isUtc: true, excludeUtcLabel: true },
                      false,
                    )}): ${li.credit_grant.name}`
                  : `Credits applied: ${li.credit_grant.name}`}
              </Subtitle>
              <Subtitle level={3} className={styles.amount}>
                <RoundedCurrency
                  amount={new Decimal(li.total)}
                  creditType={li.credit_type}
                />
              </Subtitle>
            </div>
          ))}

        {showCPUWork && hasNonZeroLineItemsInCustomCreditType ? (
          <div className={styles.line}>
            <Subtitle level={3}>
              {showCPUWork
                ? `Total (${displayCreditTypeName(invoice.credit_type)})`
                : "Total"}
            </Subtitle>
            <Subtitle level={3} className={styles.total}>
              <RoundedCurrency
                amount={totalsPerCreditType[invoice.credit_type.id].total}
                creditType={invoice.credit_type}
              />
            </Subtitle>
          </div>
        ) : null}

        <div className={classNames(styles.line, styles.invoiceTotal)}>
          <Subtitle className={showCPUWork ? "text-lg" : ""}>
            Total due
          </Subtitle>
          <Subtitle
            className={classNames(
              styles.total,
              isEmbeddableDashboard
                ? "text-default-font"
                : "text-deprecated-success-700",
            )}
          >
            <RoundedCurrency
              amount={new Decimal(invoice.total)}
              creditType={invoice.credit_type}
            />
          </Subtitle>
        </div>
      </div>
    </div>
  );
};
