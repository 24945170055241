import React from "react";
import { Label, Subtitle } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { renderDate } from "../../../../lib/time";
import { InternalLink } from "../../../Typography";
import { CorrectionsFragment } from "./fragments.graphql";
import { useOptionalParam } from "../../../../lib/routes/params";
import { EmbeddableDashboardContext } from "lib/embeddableDashboardContext";

export const CorrectedBy: React.FC<{
  customer_id: string;
  correction: CorrectionsFragment;
}> = ({ customer_id, correction }) => {
  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  const customerPlanId = useOptionalParam("customerPlanId");
  const contractId = useOptionalParam("contractId");
  const correctionRoutePath = `/customers/${customer_id}${
    customerPlanId
      ? `/plans/${customerPlanId}/`
      : contractId
        ? `/contracts/${contractId}/`
        : "/"
  }invoices/${correction.id}`;
  return (
    <div className="px-12 pt-12">
      <div className="flex items-center gap-4">
        <Subtitle level={1}>
          This invoice was corrected on{" "}
          {renderDate(new Date(correction.issued_at), {
            isUtc: true,
            excludeUtcLabel: true,
          })}
          . A{" "}
          <InternalLink routePath={correctionRoutePath}>
            credit memo was issued
          </InternalLink>
        </Subtitle>
        {!isEmbeddableDashboard ? (
          <IconButton
            className="mb-[1px] h-[12px] w-[12px]"
            theme="tertiary"
            icon="share04"
            linkTo={correctionRoutePath}
          />
        ) : null}
      </div>
      <div>
        <Label className="text-xs">
          {correction.correction_record.reason} /{" "}
          {correction.correction_record.memo}
        </Label>
      </div>
    </div>
  );
};
