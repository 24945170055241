import React from "react";
import { Themes } from "../..";
import { twMerge } from "../../twMerge";

export interface BadgeProps {
  theme: Themes;
  type: "light" | "dark";
  children?: React.ReactNode;
  className?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  theme,
  type,
  children,
  className,
}) => (
  <div
    className={twMerge(
      "inline-block rounded-small px-[6px] py-[2px] text-xxs font-bold leading-1 transition",
      (() => {
        switch (theme) {
          case "primary":
            return type === "light"
              ? "bg-deprecated-primary-50 text-deprecated-primary-600"
              : "bg-deprecated-primary-600 text-white";
          case "grey":
            return type === "light"
              ? "bg-gray-50 text-gray-500"
              : "bg-gray-600 text-white";
          case "success":
            return type === "light"
              ? "bg-success-50 text-success-600"
              : "bg-success-600 text-white";
          case "error":
            return type === "light"
              ? "bg-error-50 text-error-600"
              : "bg-error-600 text-white";
          case "warning":
            return type === "light"
              ? "bg-warning-50 text-warning-600"
              : "bg-warning-600 text-white";
        }
      })(),
      className,
    )}
  >
    {children}
  </div>
);
