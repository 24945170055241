import React from "react";
import { createPortal } from "react-dom";
import { Dayjs } from "./date";
import { IconButton } from "tenaissance/components/IconButton";

export const NowManipulator: React.FC<
  React.PropsWithChildren<{
    now: Dayjs;
    override: (value?: string) => void;
  }>
> = ({ children, now, override }) => {
  const portalTarget = React.useRef<HTMLDivElement>();

  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "F12" && e.ctrlKey) {
        setShow(!show);
        if (!show) {
          override(undefined);
        }
      }
    };

    window.addEventListener("keydown", handler, { passive: true });
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [show]);

  if (!portalTarget.current) {
    portalTarget.current = Array.from(document.body.children).find(
      (c): c is HTMLDivElement =>
        c.tagName === "DIV" && c.id === "now-manipulator",
    );
  }
  if (show && !portalTarget.current) {
    portalTarget.current = document.createElement("div");
    portalTarget.current.setAttribute("id", "now-manipulator");
    document.body.appendChild(portalTarget.current);
  }
  if (!show && portalTarget.current) {
    portalTarget.current.remove();
    portalTarget.current = undefined;
  }

  return (
    <>
      {portalTarget.current &&
        createPortal(
          <div className="absolute bottom-0 right-0 bg-deprecated-primary-200 p-12">
            <IconButton
              onClick={() => override(undefined)}
              className="mr-4 !h-auto !w-auto !p-4 align-middle"
              theme="secondary"
              icon="refreshCw01"
            />
            <input
              type="datetime-local"
              value={now.format("YYYY-MM-DDTHH:mm")}
              onChange={(e) => {
                override(e.target.value);
              }}
            />{" "}
            (UTC)
          </div>,
          portalTarget.current,
        )}
      {children}
    </>
  );
};
