import { LineItemFieldsFragment } from "./fragments.graphql";

function isContractLineItem(
  lineItem: LineItemFieldsFragment,
): lineItem is LineItemFieldsFragment & {
  __typename: `Contract${string}`;
} {
  return lineItem.__typename.startsWith("Contract");
}

export type ContractLineItem = ReturnType<
  typeof extractContractLineItems
>["contractLineItems"][number];
export type NonContractLineItem = ReturnType<
  typeof extractContractLineItems
>["nonContractLineItems"][number];

export function extractContractLineItems(lineItems: LineItemFieldsFragment[]) {
  const contractLineItems = [];
  const nonContractLineItems = [];
  for (const lineItem of lineItems) {
    if (isContractLineItem(lineItem)) {
      contractLineItems.push(lineItem);
    } else {
      nonContractLineItems.push(lineItem);
    }
  }
  return { contractLineItems, nonContractLineItems };
}
